import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterPanel = _resolveComponent("DxFilterPanel")!
  const _component_DxFilterBuilderPopup = _resolveComponent("DxFilterBuilderPopup")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_CatalogueSelectControl = _resolveComponent("CatalogueSelectControl")!
  const _component_DateControl = _resolveComponent("DateControl")!
  const _component_EnumControl = _resolveComponent("EnumControl")!
  const _component_DecimalControl = _resolveComponent("DecimalControl")!
  const _component_IntegerControl = _resolveComponent("IntegerControl")!
  const _component_CheckBoxControl = _resolveComponent("CheckBoxControl")!
  const _component_TextControl = _resolveComponent("TextControl")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_DataFillDialog = _resolveComponent("DataFillDialog")!

  return (_openBlock(), _createElementBlock("div", {
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetSelectedRows && _ctx.resetSelectedRows(...args))),
    tabindex: "0",
    ref: "area"
  }, [
    _createVNode(_component_DxDataGrid, {
      key: _ctx.dxGridRenderKey,
      ref: _ctx.refTable,
      selection: _ctx.selectionConfig,
      "data-source": _ctx.dataSource,
      "show-borders": true,
      "row-alternation-enabled": true,
      "show-column-lines": true,
      "show-row-lines": false,
      "column-min-width": 50,
      "column-auto-width": _ctx.designConfig.horizontalScroll,
      "allow-column-resizing": true,
      "filter-builder": _ctx.filterBuilder,
      "filter-value": _ctx.filterValue,
      "word-wrap-enabled": true,
      onToolbarPreparing: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToolbarPreparing($event))),
      onRowDblClick: _ctx.onRowDblClick,
      onContentReady: _ctx.onContentReady,
      onRowClick: _ctx.onRowClick,
      onInitNewRow: _ctx.onInitNewRow,
      onRowUpdating: _ctx.onRowUpdating,
      onRowUpdated: _ctx.onRowUpdated,
      onRowInserting: _ctx.onRowInserting,
      onRowInserted: _ctx.onRowInserted,
      onRowPrepared: _ctx.onRowPrepared,
      onOptionChanged: _ctx.onOptionChanged,
      onCellPrepared: _ctx.onCellPrepared,
      "hover-state-enabled": _ctx.selectionConfig.hoverStateEnabled,
      width: _ctx.designConfig.width,
      height: 
        _ctx.designConfig.pagingMode == 'paging'
          ? _ctx.designConfig.height
          : _ctx.designConfig.heightScroll
      ,
      columnMinWidth: _ctx.designConfig.columnMinWidth,
      onSelectionChanged: _ctx.onSelectionChanged
    }, {
      catalogueTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_CatalogueSelectControl, {
          onChanged: ($event: any) => (_ctx.onCatalogueTemplateValueChanged($event, cellInfo)),
          id: 
            cellInfo.data[_ctx.getColumnData(cellInfo.column.dataField).modelIdField]
          ,
          desc: 
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).modelDescField
            ]
          ,
          keyField: _ctx.getColumnData(cellInfo.column.dataField).keyField,
          displayField: _ctx.getColumnData(cellInfo.column.dataField).displayField,
          catalogueComponentName: 
            _ctx.getColumnData(cellInfo.column.dataField).catalogueComponentName
          ,
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
          filters: _ctx.catalogueSelectControlFilters
        }, null, 8, ["onChanged", "id", "desc", "keyField", "displayField", "catalogueComponentName", "hasEmptyValue", "filters"])
      ]),
      dateTimeTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_DateControl, {
          value: _ctx.getCellValue(cellInfo),
          onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
          key: _ctx.forceKeys[cellInfo.column.dataField],
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
          mode: _ctx.getColumnData(cellInfo.column.dataField).mode
        }, null, 8, ["value", "onChanged", "hasEmptyValue", "mode"])
      ]),
      enumTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_EnumControl, {
          id: 
            cellInfo.data[_ctx.getColumnData(cellInfo.column.dataField).modelField]
          ,
          desc: 
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).modelDescField
            ]
          ,
          onChanged: ($event: any) => (_ctx.onEnumTemplateValueChanged($event, cellInfo)),
          enumType: _ctx.getColumnData(cellInfo.column.dataField).enumType,
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty
        }, null, 8, ["id", "desc", "onChanged", "enumType", "hasEmptyValue"])
      ]),
      decimalTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_DecimalControl, {
          value: _ctx.getCellValue(cellInfo),
          onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
          key: _ctx.forceKeys[cellInfo.column.dataField],
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
          decimalPoints: 
            _ctx.getColumnData(cellInfo.column.dataField).decimalPoints
          ,
          step: _ctx.getColumnData(cellInfo.column.dataField).step,
          min: _ctx.getColumnData(cellInfo.column.dataField).min,
          max: _ctx.getColumnData(cellInfo.column.dataField).max
        }, null, 8, ["value", "onChanged", "hasEmptyValue", "decimalPoints", "step", "min", "max"])
      ]),
      integerTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_IntegerControl, {
          value: _ctx.getCellValue(cellInfo),
          onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
          key: _ctx.forceKeys[cellInfo.column.dataField],
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
          min: _ctx.getColumnData(cellInfo.column.dataField).min,
          max: _ctx.getColumnData(cellInfo.column.dataField).max
        }, null, 8, ["value", "onChanged", "hasEmptyValue", "min", "max"])
      ]),
      booleanTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_CheckBoxControl, {
          value: _ctx.getCellValue(cellInfo),
          onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
          key: _ctx.forceKeys[cellInfo.column.dataField]
        }, null, 8, ["value", "onChanged"])
      ]),
      textBooleanDateTimeTemplate: _withCtx(({ data: cellInfo }) => [
        (
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).dataFieldBoolean
            ] != null
          )
          ? (_openBlock(), _createBlock(_component_CheckBoxControl, {
              value: 
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).dataFieldBoolean
            ]
          ,
              onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
              key: _ctx.forceKeys[cellInfo.column.dataField]
            }, null, 8, ["value", "onChanged"]))
          : (
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).dataFieldDateTime
            ] != null
          )
            ? (_openBlock(), _createBlock(_component_DateControl, {
                value: 
            cellInfo.data[
              _ctx.getColumnData(cellInfo.column.dataField).dataFieldDateTime
            ]
          ,
                onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
                key: _ctx.forceKeys[cellInfo.column.dataField],
                hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
                mode: "datetime",
                readOnly: true
              }, null, 8, ["value", "onChanged", "hasEmptyValue"]))
            : (_openBlock(), _createBlock(_component_TextControl, {
                value: _ctx.getCellValue(cellInfo),
                onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
                key: _ctx.forceKeys[cellInfo.column.dataField],
                hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty,
                readOnly: true
              }, null, 8, ["value", "onChanged", "hasEmptyValue"]))
      ]),
      textTemplate: _withCtx(({ data: cellInfo }) => [
        _createVNode(_component_TextControl, {
          value: _ctx.getCellValue(cellInfo),
          onChanged: ($event: any) => (_ctx.onTemplateValueChanged($event, cellInfo)),
          key: _ctx.forceKeys[cellInfo.column.dataField],
          hasEmptyValue: _ctx.getColumnData(cellInfo.column.dataField).allowEmpty
        }, null, 8, ["value", "onChanged", "hasEmptyValue"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxColumnChooser, {
          height: null,
          width: null,
          enabled: _ctx.editingConfig.chooseColumns,
          mode: "select"
        }, null, 8, ["enabled"]),
        _createVNode(_component_DxFilterRow, { visible: _ctx.filterRowVisible }, null, 8, ["visible"]),
        _createVNode(_component_DxHeaderFilter, { visible: _ctx.headerFilterVisible }, null, 8, ["visible"]),
        _createVNode(_component_DxFilterPanel, { visible: _ctx.filterPanelVisible }, null, 8, ["visible"]),
        _createVNode(_component_DxFilterBuilderPopup, { position: _ctx.filterBuilderPopupPosition }, null, 8, ["position"]),
        _createVNode(_component_DxGroupPanel, { visible: false }),
        _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
        (_ctx.designConfig.pagingMode == 'scroll')
          ? (_openBlock(), _createBlock(_component_DxScrolling, {
              key: 0,
              mode: "infinite"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_DxStateStoring, {
          enabled: true,
          type: "localStorage",
          "storage-key": _ctx.storageKey
        }, null, 8, ["storage-key"]),
        _createVNode(_component_DxEditing, {
          "allow-updating": _ctx.editingConfig.allowUpdating,
          "allow-deleting": _ctx.editingConfig.allowDeleting,
          "confirm-delete": _ctx.editingConfig.confirmDelete,
          "allow-adding": _ctx.editingConfig.allowAdding,
          "use-icons": true,
          mode: _ctx.editingConfig.mode
        }, null, 8, ["allow-updating", "allow-deleting", "confirm-delete", "allow-adding", "mode"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
          return (_openBlock(), _createBlock(_component_DxColumn, {
            format: column.hideFraction ? `#,##0` : `#,##0.###`,
            "data-field": column.dataField,
            "data-type": column.dataType,
            caption: column.caption,
            key: column.dataField,
            "allow-editing": !column.readOnly,
            "validation-rules": column.validationRules,
            "edit-cell-template": column.editTemplateName,
            visible: column.visible,
            width: column.width,
            "min-width": column.minWidth,
            "calculate-display-value": _ctx.getColumnDisplayValueFunc(column),
            "sort-order": column.sortOrder ? column.sortOrder : null
          }, null, 8, ["format", "data-field", "data-type", "caption", "allow-editing", "validation-rules", "edit-cell-template", "visible", "width", "min-width", "calculate-display-value", "sort-order"]))
        }), 128)),
        (_ctx.editingConfig.mode == 'popup' && _ctx.editingConfig.allowUpdating)
          ? (_openBlock(), _createBlock(_component_DxColumn, {
              key: 1,
              buttons: _ctx.editButtons,
              type: "buttons",
              caption: "Действия"
            }, null, 8, ["buttons"]))
          : _createCommentVNode("", true),
        (_ctx.designConfig.pagingMode == 'paging')
          ? (_openBlock(), _createBlock(_component_DxPaging, {
              key: 2,
              "page-size": _ctx.pageSize
            }, null, 8, ["page-size"]))
          : _createCommentVNode("", true),
        (_ctx.designConfig.pagingMode == 'paging')
          ? (_openBlock(), _createBlock(_component_DxPager, {
              key: 3,
              "show-page-size-selector": _ctx.showPageSizeSelector,
              "allowed-page-sizes": _ctx.pageSizes,
              "show-info": true,
              visible: _ctx.dxPagerVisible
            }, null, 8, ["show-page-size-selector", "allowed-page-sizes", "visible"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["selection", "data-source", "column-auto-width", "filter-builder", "filter-value", "onRowDblClick", "onContentReady", "onRowClick", "onInitNewRow", "onRowUpdating", "onRowUpdated", "onRowInserting", "onRowInserted", "onRowPrepared", "onOptionChanged", "onCellPrepared", "hover-state-enabled", "width", "height", "columnMinWidth", "onSelectionChanged"]),
    (_ctx.popupCreateEditComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.popupCreateEditComponent), {
          key: 0,
          saveModelCallback: _ctx.popupCreateEditSaveModelCallback,
          ref: "popupCreateEdit"
        }, null, 8, ["saveModelCallback"]))
      : _createCommentVNode("", true),
    (_ctx.popupInfoComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.popupInfoComponent), {
          key: 1,
          ref: "popupInfo"
        }, null, 512))
      : _createCommentVNode("", true),
    (_ctx.editingConfig.allowDataFill)
      ? (_openBlock(), _createBlock(_component_DataFillDialog, {
          key: 2,
          ref: "dataFillDialog",
          columns: _ctx.getEditableColumns(),
          grid: _ctx.dxGrid,
          controller: _ctx.controller,
          parentModel: _ctx.parentModel,
          dataGrid: _ctx.dxGrid
        }, null, 8, ["columns", "grid", "controller", "parentModel", "dataGrid"]))
      : _createCommentVNode("", true)
  ], 544))
}