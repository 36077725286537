
/* eslint-disable */
import { defineComponent } from "vue";
import {
  DxDataGrid,
  DxEditing,
  DxPopup,
  DxPosition,
  DxForm,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxPaging,
  DxPager,
  DxScrolling,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxToolbarItem,
  DxColumnChooser,
  DxStateStoring
} from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";
import { ColumnConfig } from "@/components/controls/catalogueGrid/columns/columnConfig";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import TextControl from "@/components/controls/base/TextControl.vue";
import TextAreaControl from "@/components/controls/base/TextAreaControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import DateControl from "@/components/controls/base/DateControl.vue";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import DefinePasswordControl from "@/components/controls/DefinePasswordControl.vue";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { CatalogueColumnConfig } from "./columns/catalogueColumnConfig";
import { EnumColumnConfig } from "./columns/enumColumnConfig";
import DataFillDialog from "@/components/controls/catalogueGrid/DataFillDialog.vue";
import {DateTime} from "luxon";
import { confirm } from 'devextreme/ui/dialog';

export default defineComponent({
  components: {
    DxDataGrid,
    DxEditing,
    DxPopup,
    DxPosition,
    DxForm,
    DxItem,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxStateStoring,
    DxPaging,
    DxPager,
    DxScrolling,
    DxFilterPanel,
    DxFilterBuilderPopup,
    TextControl,
    TextAreaControl,
    CheckBoxControl,
    IntegerControl,
    DecimalControl,
    DateControl,
    EnumControl,
    DxToolbarItem,
    CatalogueSelectControl,
    DefinePasswordControl,
    DataFillDialog,
    DxColumnChooser
  },
  props: {
    //список элементов тулбара
    toolbarItems: {required: false},

    // callback при добавлении новой записи
    getCreateCallback: {required: false},

    //компонента диалогового окна создания\редактирования записи
    popupCreateEditComponent: {required: false},

    //компонента диалогового окна информации о таблице
    popupInfoComponent: {required: false},

    //модель родительского элемента
    parentModel: {required: false},

    //список конфигураций колонок таблицы - обязательный
    columns: { required: true, type: Array }, //ColumnConfig[]
    //объект конфигурации возможностей редактирования таблицы (CRUD) - обязательный
    editingConfig: { required: true, type: TableEditingConfig }, //TableEditingConfig
    //объект конфигурации режима выбора строк в таблице - обязательный
    selectionConfig: { required: true, type: TableSelectionConfig },
    //объект конфигурации внешнего вида таблицы
    designConfig: { required: true, type: TableDesignConfig },
    //наименование контроллера, к которому подключается таблица в серверном режиме
    controller: { required: false, type: String },

    showPageSizeSelector: { required: true, type: Boolean },

    pageSize: { required: true, type: Number },

    pageSizes: { required: false }, //number[]

    filterRowVisible: { required: true, type: Boolean },

    getSelectedData: { type: Function },

    changeSelectedData: { type: Function },

    headerFilterVisible: { required: true, type: Boolean },

    groupPanelVisible: { required: true, type: Boolean },

    filterPanelVisible: { required: true, type: Boolean },

    filterBuilder: { required: false, type: Object },

    filterValue: { required: false, type: Array },

    keyExpr: { required: false, type: String },

    customDataSource: { required: true },

    selectMode: { required: false },
    
    pushDataToSelectControl: { required: false, type: Boolean, default: false},

    additionalFilters: {required: false},
  },
  watch: {
    customDataSource: function(value){
        this.dataSource = value;
    }
  },
  methods: {
    onCellPrepared(e: any){
      if(e.rowType == "header")
        e.cellElement.style.textAlign = "center";
    },

    resetSelectedRows(){
      if (this.editingConfig.allowResetSelectedRows && this.dxGrid.getSelectedRowKeys){
        let selectedRowsKeys = this.dxGrid.getSelectedRowKeys();
        for (var i = 0; i < selectedRowsKeys.length; i++) {  
          this.dxGrid.deselectRows([selectedRowsKeys[i]]);  
        }
      }
      this.$refs.area.blur()
    },

    getEditableColumns() {
      return this.columns.filter((x: any) => x.editVisible && !x.readOnly);
    },
     //получение значения ячейки таблицы для связанного с ней контролла редактирования
    getCellValue(cellInfo: any) {
        if (cellInfo.value === 0) return 0;
        if (cellInfo.value) return cellInfo.value;
        const columnData = this.getColumnData(cellInfo.column.dataField);
        return columnData.defaultValue;
      },
    // Обработчик изменения значение в CatalogueSelectControl в режиме редактирования Cell
    onCellCatalogueTemplateSelect(cellInfo: any){
        const dialog = (this.$refs.catalogueSelectDialog as any);
        const keyField = this.getColumnData(cellInfo.column.dataField).keyField;
        const displayField = this.getColumnData(cellInfo.column.dataField).displayField;
        const catalogueComponentName = this.getColumnData(cellInfo.column.dataField).catalogueComponentName;
        dialog.show(displayField, keyField, catalogueComponentName, cellInfo);
    },

    //для синхронизации контрола в шаблоне enum и таблицы
  onEnumTemplateValueChanged(e: any, cellInfo: any){
      const modelDescField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as EnumColumnConfig).modelDescField;

      const modelField = (this.getColumnData(
        cellInfo.column.dataField,
      ) as EnumColumnConfig).modelField;

      if(e.id != null){
        cellInfo.data[modelField] = e.id;
        cellInfo.data[modelDescField] = e.desc;
        cellInfo.setValue(e.id);
        (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, e.desc);

        //Для обхода бага
        this.selectTransferObj[modelField] = e.id;
      }else{
          cellInfo.data[modelField] = null;
          cellInfo.data[modelDescField] = '';
          cellInfo.setValue(null);
          (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, '');

          //Для обхода бага
          this.selectTransferObj[modelField] = null;
      }
  },

    // Обработчик изменения значения при выборе из CatalogueSelectControl в режиме редактирования Cell и Row
     onCatalogueTemplateValueChanged(e: any, cellInfo: any) {
          if (cellInfo.setValue) {
            const modelDescField = (this.getColumnData(
              cellInfo.column.dataField,
            ) as CatalogueColumnConfig).modelDescField;

            const modelIdField = (this.getColumnData(
              cellInfo.column.dataField,
            ) as CatalogueColumnConfig).modelIdField;

            if (e.id) {
              cellInfo.data[modelIdField] = e.id;
              cellInfo.data[modelDescField] = e.desc;
              cellInfo.setValue(e.id);
              (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelIdField, e.id);
              (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, e.desc);

              //Для обхода бага
              this.selectTransferObj[modelIdField] = e.id;
            } else {
              cellInfo.data[modelIdField] = null;
              cellInfo.data[modelDescField] = '';
              cellInfo.setValue(null);
              (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelIdField, null);
              (this.dxGrid as any).cellValue(cellInfo.row.rowIndex, modelDescField, '');

              //Для обхода бага
              this.selectTransferObj[modelIdField] = null;
            }
          }
  },

  onRowUpdating(e: any){
      if(this.editingConfig.mode == 'cell' || this.editingConfig.mode == 'row'){
        e.newData = {...e.newData,...this.selectTransferObj};
        this.storeManager.setEditModel(this.dxGridRenderKey, {...e.oldData});
      }
    },

    onRowUpdated(e: any){
      this.selectTransferObj = {};
    },

    onRowInserting(e: any){
       if(this.editingConfig.mode == 'cell' || this.editingConfig.mode == 'row'){
          e.data = {...e.data,...this.selectTransferObj};
       }
    },

    onRowInserted(e: any){
        this.selectTransferObj = {};
    },

    onOptionChanged(e: any){
      if (e.name == "columns" && e.fullName.includes("filterValue")){
        this.isFilter = false;
        for (let index = 0; index < this.columns.length; index++){
          if (e.component.columnOption(index, 'filterValues') || e.component.columnOption(index, 'filterValue')){
            this.isFilter = true;
            break;
          }
        }
        this.dxGrid.repaint();

        let cells = this.dxGrid._$element.find("td");
        for (let index = 0; index < this.columns.length; index++){
          let element = cells[0].className.includes("dx-datagrid-group-space") ? cells[index + 1] : cells[index];
          if (e.component.columnOption(index, 'filterValues')){
            element.style.fontWeight = "700";
            element.style.color = "#363B5D";
          }
          else{
              element.style.fontWeight = "";
              element.style.color = "";
          }
        }
      }
    },

    onRowPrepared(e: any) {
      if (e.rowType == "header"){
        let index = 0;
        e.columns.forEach((column: any) => {
          if(column.filterValues){
            e.cells[index].cellElement.style.fontWeight = "700";
            e.cells[index].cellElement.style.color = "#363B5D";
          }
          index++;
        })
      }  
    },

    //метод для получения отображаемого значения для description колонок, если они видны
    getColumnDisplayValueFunc(column: any) :any {
       if(column.customDisplayCallback){
        return (rowData: any) => {
          return column.customDisplayCallback(rowData[column.dataField]);
        };
      }

      if (column.descriptionColumnName) {
        return (rowData: any) => {
          return rowData[column.descriptionColumnName];
        };
      }
      if (column.editTemplateName === "dateTimeTemplate") {
        return (rowData: any) => {
          const value = rowData[column.dataField];
          if(!value)
            return "";
          const date = column.isUtc ? DateTime.fromISO(value, { zone: "utc"}).toLocal() : DateTime.fromISO(value).toLocal();
          if(column.mode == "datetime")
            return date.toFormat("dd.MM.yyyy HH:mm");
          return date.toFormat("dd.MM.yyyy");
        };
      }
     if (column.editTemplateName === "textBooleanDateTimeTemplate") {
        return (rowData: any) => {
          const value = rowData[column.dataField];
          const valueBoolean = rowData[column.dataFieldBoolean];
          const valueDateTime = rowData[column.dataFieldDateTime];
          if (valueBoolean != null)
            return valueBoolean;
          else if (valueDateTime != null)
            return valueDateTime;
          else
            return value;
        };
      }
      return undefined;
    },

    getColumnDisabled(cellInfo: any) : any {
      const column = this.getColumnData(cellInfo.column.dataField);
      if (column.disabledAction && cellInfo)
        return column.disabledAction(cellInfo.row.data) || column.disabled;
      return column.disabled;
    },

    getColumnData(dataField: string) : any {
      return ((this as any).columns as any).filter(
        (x: ColumnConfig) => x.dataField == dataField
      )[0];
    },

    //при изменении содержимого
    onContentReady(e: any) {

       /* Undocumented !!! */
      const columnChooserView = e.component.getView("columnChooserView");  
      if (columnChooserView && !columnChooserView._popupContainer) {  
          columnChooserView._initializePopupContainer();  
          columnChooserView.render();  
          columnChooserView._popupContainer.option("position", { of: document.body, my: "right bottom", at: "right bottom", offset: "0 -100"});  
      } 
      this.refreshDataKey = this.refreshDataKey + 1;
      (this as any).dxGrid = e.component;
      if (Array.isArray((this as any).dataSource)) {
        ((this as any).dxGrid as any).keyExpr = (this as any).keyExpr;
      }

      if (this.firstDisplay){
        let button = e.component._$element.find("#clearFilterButton");
        if (button.length != 0){
          if (e.component.getCombinedFilter()){
             button[0].style.visibility = "visible";
            this.isFilter = true;
          }
          else {
            button[0].style.visibility = "hidden";
          }
        }
        this.firstDisplay = false;
      }

      (this as any).$emit("content-ready", e);
    },

    onRowClick(e: any) {
      // Возможно event.stopPropagation потребуется в onRowClick (т.к. он вызывается в master при вызове в detail)
      // но тогда rowDblClick из dev-extreme не будет работать!

      (this as any).$emit("row-click", e);
      if ((this as any).changeSelectedData && !e.data.collapsedItems)
        (this as any).changeSelectedData(((this as any).dxGrid as any).getSelectedRowsData());
    },

    onRowDblClick(e: any) {
      //предотвращаем вызов события в master таблице, если событие из detail таблицы
      e.event.stopPropagation();
      
      (this as any).$emit("row-dblclick", e);
      if ((this as any).getSelectedData && !e.data.collapsedItems)
        (this as any).getSelectedData(((this as any).dxGrid as any).getSelectedRowsData());

      if(!this.selectMode && this.editingConfig.allowUpdating && this.editingConfig.rowDblClickEditing){
        if(this.editingConfig.mode == 'popup')
          this.editRow({row: e});
        else
          this.dxGrid.editRow(e.rowIndex);
      }
    },

    deleteSelectedRows() {
      let result = confirm("Вы уверены, что хотите удалить выбранные записи?", "Подтверждение действия");
      result.then((dialogResult: any) => {
        if(!dialogResult)
          return;
        let selectedRowsKeys = this.dxGrid.getSelectedRowKeys();
        selectedRowsKeys.forEach((key: any) => {
          this.dataSource.remove(key).then(() => {
            this.dxGrid.refresh();
          })
        })
      })
    },

    changeScrollSettings(){
      this.designConfig.horizontalScroll = !this.designConfig.horizontalScroll
      this.$emit('remember-scroll-settings', this.designConfig.horizontalScroll);
    },

    dataFillDialog(){
      return this.$refs.dataFillDialog as any;
    },

    async showDataFillDialog(){
      let filterExpr = this.dxGrid.getCombinedFilter();
      let gridDataSource = this.dxGrid.getDataSource();
      const {data} = await gridDataSource.store().load({
        filter: filterExpr
      });
      let selectedRowsData = data;
      return this.dataFillDialog().show(true, selectedRowsData);
    },

    showInfoDialog(){
      return this.popupInfo().open();
    },

    popupCreateEdit(){
      return this.$refs.popupCreateEdit as any;
    },

    popupInfo(){
      return this.$refs.popupInfo as any;
    },

    onSelectionChanged(e: any){
      this.selectedRowsData = e.selectedRowsData;
      this.$emit('selection-changed',e);
    },

    clearFilter(){
      this.dxGrid.clearFilter("row");
      this.dxGrid.clearFilter("header");
      this.isFilter = false;
      this.dxGrid.repaint();
    },

    /*Toolbar таблицы кастомизируется с помощью изменения объекта toolbarOptions*/
    onToolbarPreparing(e: any): void {
      //удаляем стандартную кнопку добавления
      
      e.toolbarOptions.items = e.toolbarOptions.items.filter(
        (x: any) => x.name != "addRowButton"
      );

      if (this.editingConfig.showInfo) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'info',
              hint: 'Справка',
              onClick: this.showInfoDialog.bind(this)
            },
          });
      }

      if (this.editingConfig.allowDataFill) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'decreaseindent',
              hint: 'Заполнение данных',
              onClick: this.showDataFillDialog.bind(this)
            },
          });
      }

      if (this.editingConfig.allowDeleteMultipleRow) {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'trash',
              hint: 'Удаление выбранных записей',
              onClick: this.deleteSelectedRows.bind(this)
            },
          });
      }

      if (this.editingConfig.allowClearFilter) {
        e.toolbarOptions.items.unshift({
          disabled: !this.isFilter,
          location: "after",
          widget: "dxButton",
          options: {
            text: "Очистить фильтр",
            type: "default",
            stylingMode: "text",
            onClick: this.clearFilter.bind(this),
          },
        });
      }

      if(this.toolbarItems){
        this.toolbarItems.forEach((x:any) => {
           e.toolbarOptions.items.unshift(x);
        });
      }

      if (this.editingConfig.allowChangeScrollSettings) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            name: "horizontalScrollButton",
            icon: "aligncenter",
            hint: "Изменение ширины таблицы",
            stylingMode: "text",
            onClick: this.changeScrollSettings,
          }
        });
      }

      if (this.editingConfig.allowAdding) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            text: "Добавить",
            type: "default",
            stylingMode: "outlined",
            onClick: this.createNewRow.bind(this),
          },
        });
      }

      if (this.editingConfig.allowExport) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "download",
            hint: "Экспорт в Excel",
            stylingMode: "text",
            onClick: this.exportToExcel.bind(this),
          },
        });
      }
    },

    updateAdditionalFilters(additionalFilters: any){
      this.storeManager.setAdditionalFilters(this.dxGridRenderKey, additionalFilters);
      this.dxGrid.refresh();
    },

    onInitNewRow(e: any){
      if(this.getCreateCallback){
          e.data = this.getCreateCallback();
      }
    },
    // Добавление новой записи
    createNewRow(): void {
      if(!this.remoteDataSource){
        const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
        component.addRow();
      };
      if(this.editingConfig.mode == 'popup'){
        this.remoteDataSource.getCreate().then((model: any) => {
          this.popupCreateEdit().open({...model, localId: this.guidHelper.getUniqueId(4)},'create')
        });     
      }else{
        const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
        component.addRow();
      }
    },

    editRow(e: any): void {
      this.popupCreateEdit().open({...e.row.data},'edit')
    },

    deleteRow(e: any): void {
      const editRowIndex = e.component.getRowIndexByKey(e.row.key);
      e.component.deleteRow(editRowIndex);
    },

    popupCreateEditSaveModelCallback(model:any, popup:any) {
      const dataSource = (this as any).dataSource;
      const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
      let update = false;
      for(let i = 0; i < dataSource.length; ++i){
        if(dataSource[i].localId === model.localId){
             dataSource[i] = {...model};
            update = true;
        }
      }
      if(!update)
        dataSource.push({...model});
      component.refresh();
      popup.close();
    },

    exportToExcel(): void {
      const component: any = (this.$refs[this.refTable] as DxDataGrid).instance;
      component.exportToExcel();
    },

    //для синхронизации темплейтов редатирования строки и таблицы
    columnUpdate(cellInfo: any) {
      const column = this.getColumnData(cellInfo.column.dataField);
      column.repaintControls?.forEach((x:any) => {
        (this as any).forceKeys[x] += 1;
      });
    },

    //для синхронизации темплейтов редатирования строки и таблицы
    onTemplateValueChanged(e: any, cellInfo: any) {
      if (cellInfo.setValue) cellInfo.setValue(e.value);
      this.columnUpdate(cellInfo);
    }
  },
  computed: {
    catalogueSelectControlFilters(): any {
      this.refreshDataKey;
      if(this.pushDataToSelectControl && this.dxGrid){
        return this.dxGrid.getDataSource().items();
      }
      else return [];
    },
    tableInstance(): any {
      return ((this as any).$refs[(this as any).refTable] as DxDataGrid)
        .instance;
    },
    storageKey(): any {
      if (!this.selectMode)
        return `${this.controller}Storage`;
      else 
        return `${this.controller}StorageSelectMode`;
    },
    dxPagerVisible(): any {
      if(!this.dxGrid.totalCount || !this.pageSizes)
        return false
      const rowCount = this.dxGrid.totalCount();
      const minSize = Math.min(...this.pageSizes)
      return minSize < rowCount && this.showPageSizeSelector
    }
  },
  data() {
    return {
      firstDisplay: true,
      isFilter: false,
      selectedRowsData: [],
      selectTransferObj: {},
      forceKeys: {},
      guidHelper: new GuidHelper(),
      dxGridRenderKey: "",
      dataSource: {},
      remoteDatasource: null,
      storeManager: new StoreManager(),
      dxGrid: {},
      entityModel: {},
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      editButtons: [
        { hint: "Редактировать", icon: "edit", onClick: this.editRow },
        { hint: "Удалить", icon: "trash", onClick: this.deleteRow },
      ],
      refTable: "refTable",
      cancelButtonOptions: {
        text: "Отмена",
        onClick: () => {
          if (this.tableInstance) {
            (this.tableInstance as any).cancelEditData();
          }
        },
      },
      refreshDataKey: 1,
    };
  },

  created() {
    if(this.customDataSource)
        this.dataSource = (this as any).customDataSource;

    this.dxGridRenderKey = this.guidHelper.getUniqueId(4);

    //установка parent модели
    this.storeManager.setParentModel(this.dxGridRenderKey, {...this.parentModel});

    //создание серверного хранилища данных
    if(this.controller){
      this.remoteDataSource = this.storeManager.getStandartGridCrudStore(
        this.controller,
        this.dxGridRenderKey,
      );
    }
    //инициализируем ключи обновления контролов шаблонов
    this.columns.forEach( (x: any) => {
      this.forceKeys[x.dataField] = 0;
    });

    // TODO - убрать, когда будет ясно, используем scroll или paging
    this.selectionConfig.selectAllMode = this.designConfig.pagingMode == "scroll" ?  "page" : "allPages";

    //установка доплнительных фильтров
    this.storeManager.setAdditionalFilters(this.dxGridRenderKey, (this as any).additionalFilters);
  },
});
